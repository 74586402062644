<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :columns="columns"
      guard="countries"
    >
      <template #cell(country_name_en)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.flag"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.flag"
              :text="avatarText(data.item.country_name_en)"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.country_name_en }}
          </b-link>
        </b-media>
      </template>
    </general-table>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'countries',
      addType: 'Add Country',
      addComponentName: 'add-friencountryd',
      editComponent: 'edit-country',
      columns: [
        { key: 'id', sortable: true },
        { key: 'country_name_en', label: 'Country' },
        { key: 'currency_name', label: 'Currency Name' },
        { key: 'currency_code', label: 'Currency Code' },
        { key: 'currency_symbol', label: 'Currency Symbol' },
        { key: 'is_active', label: 'Active' },
        // { key: 'actions' },
      ],
      avatarText,
    }
  },
}
</script>

<style>

</style>
